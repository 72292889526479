// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "q_q5 d_gv d_cs";
export var heroHeaderCenter = "q_gw d_gw d_cs d_dw";
export var heroHeaderRight = "q_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "q_q6 d_gr d_cw";
export var heroParagraphCenter = "q_gs d_gs d_cw d_dw";
export var heroParagraphRight = "q_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "q_q7 d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "q_q8 d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "q_q9 d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "q_rb d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "q_rc d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "q_rd v_rd";
export var heroExceptionNormal = "q_rf v_rf";
export var heroExceptionLarge = "q_rg v_rg";
export var Title1Small = "q_rh v_rh v_rF v_rG";
export var Title1Normal = "q_rj v_rj v_rF v_rH";
export var Title1Large = "q_rk v_rk v_rF v_rJ";
export var BodySmall = "q_rl v_rl v_rF v_rY";
export var BodyNormal = "q_rm v_rm v_rF v_rZ";
export var BodyLarge = "q_rn v_rn v_rF v_r0";