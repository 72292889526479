// extracted by mini-css-extract-plugin
export var lbContainerOuter = "y_tC";
export var lbContainerInner = "y_tD";
export var movingForwards = "y_tF";
export var movingForwardsOther = "y_tG";
export var movingBackwards = "y_tH";
export var movingBackwardsOther = "y_tJ";
export var lbImage = "y_tK";
export var lbOtherImage = "y_tL";
export var prevButton = "y_tM";
export var nextButton = "y_tN";
export var closing = "y_tP";
export var appear = "y_tQ";